<template>
  <BaseLoad v-if="loading" />
  <div class="container wrapper" v-else>
    <div class="content">
      <BaseTop />
      <div class="con">
        <ul class="list">
          <li class="item" v-for="(item, index) in list" :key="item.game_id">
            <div class="item-t flex" @click.prevent="toGameInfo(item.game_id)">
              <img class="item-l" :src="preSrc + item.cover" alt="" />
              <div class="item-r">
                <h3>{{ item.name }}</h3>
                <p class="desc">{{ item.desc }}</p>
                <div class="flex favor-work">
                  <CancelFavor
                    v-if="item.collect_status == 1"
                    :cId="item.game_id"
                    :cStatus="2"
                    :cIndex="index"
                    :cItem="item"
                    @favorCancel="handleFavorCanel"
                  />
                  <BaseFavor
                    v-else
                    :cId="item.game_id"
                    :cStatus="1"
                    :cIndex="index"
                    :cItem="item"
                    @favorStatus="handleFavor"
                  />
                </div>
              </div>
            </div>
            <div class="item-b between">
              <div>
                <span class="price">￥{{ item.price }}</span>
                <del class="del-price">原价：{{ item.original_price }}</del>
              </div>
              <div>
                <button class="btn" v-if="item.buy_status == 1">
                  <i class="iconfont icon-duihao"></i> 已购买
                </button>
                <button class="btn" v-else @click="toPurchase(item.game_id, 1)">
                  立即购买
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <BaseCopyright />
  </div>
</template>
<script>
import BaseTop from "@/components/top/top.vue";
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
export default {
  components: {
    BaseTop,
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      loading: true,
      preSrc: config.src,
      list: [],
      total: 0,
      pageSize: 10,
    };
  },
  created() {
    let _self = this;
    _self.initData(1);
  },
  methods: {
    initData(page) {
      let _self = this;
      _self.axios
        .post(config.url.gameList, _self.$qs.stringify({ page, size: 10 }))
        .then((res) => {
          if (res.code == 200) {
            _self.list = res.data.list;
            _self.total = res.data.total;
            setTimeout(() => {
              _self.loading = false;
            }, 150);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 取消收藏
    handleFavorCanel(status, index, item) {
      let _self = this;
      item.collect_status = status;
      _self.$set(_self.list, index, item);
    },
    // 收藏
    handleFavor(status, index, item) {
      let _self = this;
      item.collect_status = status;
      _self.$set(_self.list, index, item);
    },
    //游戏详情
    toGameInfo(id) {
      let _self = this;
      _self.$router.push({
        name: "game-info",
        params: {
          id: id,
        },
      });
    },
    toPurchase(id, type) {
      let _self = this;
      _self.$router.push({
        name: "purchase",
        params: {
          id: id,
          type: type,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.con {
  .list {
    margin-top: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .item {
    background: #fff;
    padding: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    width: 580px;
    height: 300px;
    margin: 0 40px 60px 0;
    border-radius: $border-radius-main;
    .item-l {
      width: 160px;
      height: 160px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: 50%;
      object-fit: contain;
    }
    .item-r {
      width: 360px;
      height: 160px;
      flex-shrink: 0;
      flex-grow: 0;
      padding-left: 30px;
      box-sizing: border-box;
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 16px;
        color: $font-color-81;
        line-height: 30px;
        height: 60px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数
        -webkit-box-orient: vertical;
      }
      .price {
        font-size: 30px;
        color: $color-main;
        margin-right: 20px;
        font-weight: bold;
      }
      .del-price {
        font-size: 18px;
        color: $font-color-9f;
      }
      .favor-work {
        color: $font-color-9f;
        justify-content: flex-start;
        .slash {
          margin: 0 15px;
        }
        .favor {
          margin-top: -3px;
        }
        .work img {
          margin-right: 10px;
          display: inline-block;
          width: 18px;
          height: 18px;
          object-fit: contain;
        }
        .favor i {
          margin-right: 6px;
        }
        .icon-shoucang,
        .icon-star-filled {
          color: #f9e457;
        }
      }
      .icon-duihao {
        margin-right: 5px;
      }
    }
    .item-b {
      margin-top: 30px;
      border-radius: 5px 20px 20px 5px;
      .btn {
        width: 200px;
      }
    }
    .price {
      font-size: 30px;
      color: $color-main;
      font-weight: bold;
      margin: 0 20px 0 30px;
    }
    .del-price {
      font-size: 18px;
      color: $font-color-9f;
    }
  }
  .item:nth-child(2n) {
    margin-right: 0;
  }
  .item:hover {
    cursor: pointer;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
    .item-r h3 {
      color: $color-main;
    }
  }
}
</style>
